import { Cadastro } from '../../bases/Cadastros/Cadastro';

class Empresa extends Cadastro {
  constructor(persistente) {
    super();

    this.descricao = persistente ? '' : 'INDEFINIDO';
  }

  get json() {}

  set json(dados) {
    this.descricao = dados.nome;
    this.bairro = dados.bairro;
    this.cep = dados.cep;
    this.cidade = dados.cidade;
    this.endereco = dados.endereco;
    this.uf = dados.uf;
  }
}

export { Empresa };
