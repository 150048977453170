export default [
  {
    path: '/cliente/consulta',
    name: 'consulta_cliente',
    component: () => import('@/views/cliente/consulta/ConsultaCliente.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/cliente',
    name: 'novo_cliente',
    component: () => import('@/views/cliente/form/FormCliente.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/cliente/:id',
    name: 'detalhe_cliente',
    component: () => import('@/views/cliente/form/FormCliente.vue'),
    meta: {
      requireAuth: true,
    },
  },
];
