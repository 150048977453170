const cacheName = 'v1.3.0';

const resourcesToPrecache = ['/', 'index.html'];

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js');
  });
}

self.addEventListener('install', event => {
  event.waitUtil(
    caches.open(cacheName).then(cache => cache.addAll(resourcesToPrecache)),
  );
});

self.addEventListener('fetch', event => {
  event.respondWith(
    caches
      .match(event.request)
      .then(cacheResponse => cacheResponse || fetch(event.request)),
  );
});
