import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { Setor } from '../Setor/Setor';
import { Cliente } from '../Cliente/Cliente';

import { Sessao } from '../Autenticacao/Sessao';
import { Storage } from '../Storage/Storage';

Sessao.json = Storage.getSessao();
export class Contato extends Cadastro {
  constructor(persistente) {
    super();

    this.descricao = persistente ? '' : 'INDEFINIDO';
    this.setor = new Setor();
    this.cliente = new Cliente();
    this.cpf = '';
    this.ativo = false;
    this.atendeAtendimentoTelefonico = false;
    this.atendimentoPresencial = false;

    if (Sessao.usuario && Sessao.usuario.cliente.codigo > 0) {
      const { usuario } = Sessao;

      this.cliente.codigo = usuario.cliente.codigo;
      this.cliente.descricao = usuario.descricao;
    }
    this.solicitao = false;
  }

  mapeamento = {
    codigo: 'codigoClc',
    descricao: 'nomeClc',
    'cliente.codigo': 'clienteClc',
    'cliente.descricao': 'razaoCli',
    setorCodigo: 'setorClc',
    setorDescricao: 'descricaoSetor',
    funcao: 'funcaoClc',
    telefone: 'telefoneClc',
    email: 'emailClc',
    cpf: 'cpfClf',
    observacoes: 'observacoesClc',
    procurador: 'procuradorClc',
    atendeAtendimentoTelefonico: 'atdtelefonicoClc',
    atendimentoPresencial: 'atdpresencialClc',
    solicitacao: 'solicitacaoClc',
    ativo: 'ativoClc',
    papel: 'descricaoTippap',
    situacaoEmail: 'emailsituacaoClc',
  };

  get json() {
    return {
      codigoClc: this.codigo,
      nomeClc: this.descricao,
      clienteClc: this.cliente.codigo,
      razaoCli: this.cliente.descricao,
      setorClc: this.setor.codigo,
      descricaoSetor: this.setor.descricao,
      funcaoClc: this.funcao,
      telefoneClc: this.telefone,
      emailClc: this.email,
      cpfClf: this.cpf,
      observacoesClc: this.observacoes,
      procuradorClc: this.procurador === true ? 'S' : 'N',
      atdtelefonicoClc: this.atendeAtendimentoTelefonico === true ? 'S' : 'N',
      atdpresencialClc: this.atendimentoPresencial === true ? 'S' : 'N',
      solicitacaoClc: this.solicitao === true ? 'S' : 'N',
      ativoClc: this.ativo === true ? 'S' : 'N',
    };
  }

  set json(dados) {
    this.codigo = dados.codigoClc || dados.contatoEcc;
    this.cliente.codigo = dados.clienteClc;
    this.cliente.descricao = dados.razaoCli;
    this.cpf = dados.cpfClf;
    this.setor.codigo = dados.setorClc;
    this.setor.descricao = dados.descricaoSetor;

    this.codigoDoCliente = dados.clienteClc;
    this.descricao = dados.nomeClc;
    this.funcao = dados.funcaoClc;

    this.telefone = dados.telefoneClc;
    this.email = dados.emailClc;
    this.cpf = dados.cpfClf ? dados.cpfClf : '';
    this.observacoes = dados.observacoesClc;
    this.procurador = dados.procuradorClc === 'S' ? true : false;
    this.atendeAtendimentoTelefonico =
      dados.atdtelefonicoClc === 'S' ? true : false;
    this.procurador = dados.procuradorClc === 'S' ? true : false;
    this.atendimentoPresencial = dados.atdpresencialClc === 'S' ? true : false;
    this.solicitao = dados.solicitacaoClc === 'S' ? true : false;
    this.ativo = dados.ativoClc === 'S' ? true : false;
    this.papel = dados.descricaoTippap;
    this.situacaoEmail = this.setEmailSituacao(dados.emailsituacaoClc);
  }

  setEmailSituacao(situacao) {
    switch (situacao) {
      case 0:
        return 'Não validado';
      case 1:
        return 'Inválido';
      case 2:
        return 'Válido';
    }
  }

  setCliente(cliente) {
    this.cliente.codigo = cliente.codigo;
    this.cliente.descricao = cliente.descricao;
  }
}
