import { DataUtils } from '../../../utils/DataUtils';

class Arquivo {
  constructor() {
    this.codigo = 0;
    this.descricao = '';
    this.nome = '';
    this.dataInclusaoArquivo = '';
    this.tipo = '';
    this.arquivoEncoded = '';
    this.codigoDocumentoOrigemArquivo = 0;
    this.origemArquivo = '';
  }

  getNomeTipoArquivo(tipoArquivo) {
    switch (tipoArquivo) {
      case 0:
        return 'ARQUIVO';
      case 1:
        return 'TEXTO';
      case 2:
        return 'IMAGEM';
      case 3:
        return 'VIDEO';
      case 4:
        return 'PDF';
    }
  }

  getTipoArquivo(nomeTipo) {
    switch (nomeTipo.toUpperCase()) {
      case 'ARQUIVO':
        return 0;
      case 'TEXTO':
        return 1;
      case 'IMAGEM':
        return 2;
      case 'VIDEO':
        return 3;
      case 'PDF':
        return 4;
    }
  }

  set json(dados) {
    this.codigo = dados.codigoArq;
    this.dataInclusaoArquivo = DataUtils.formatarTimeZoneParaDataHora(
      dados.datahorainclusaoArq,
    );
    this.descricao = dados.descricaoArq;
    this.tipo = this.getNomeTipoArquivo(dados.tipoArq);
    this.arquivoEncoded = dados.arquivoArq;
    this.nome = dados.nomeArq;
    this.codigoDocumentoOrigemArquivo = dados.docorigemArq;
  }

  get json() {
    return {
      arquivoArq: this.arquivoEncoded,
      descricaoArq: this.descricao,
      docorigemArq: this.codigoDocumentoOrigemArquivo,
      nomeArq: this.nome,
      origemArq: this.origemArquivo,
      tipoArq: this.getTipoArquivo(this.tipo),
    };
  }
}
export { Arquivo };
