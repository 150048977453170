import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import VueZoomer from 'vue-zoomer';

import './registerServiceWorker';

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueZoomer);

new Vue({
  vuetify,
  router,
  render: h => h(App),
}).$mount('#app');
