import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { ModuloSistema } from './ModuloSistema';
class Sistema extends Cadastro {
  constructor(persistente) {
    super();
    this.descricao = persistente ? '' : 'INDEFINIDO';
    this.modulos = [];
  }

  setModulos(dados) {
    this.modulos = [];
    dados.map(registro => {
      const modulo = new ModuloSistema();
      modulo.json = registro;
      this.modulos.push(modulo);
    });
  }

  get json() {}

  get jsonParaCliente() {
    return {
      codigoSsc: this.sistemaCliente,
      clienteSsc: this.cliente,
      sistemaSsc: this.codigo,
      statusSsc: this.status,
      codversaoSsc: this.codigoVersao,
      nomeSis: this.descricao,
      versaoSve: this.versao,
    };
  }

  set json(dados) {
    this.sistemaCliente = dados.codigoSsc;
    this.codigo = dados.sistemaSsc || dados.codigoSis;
    this.descricao = dados.nomeSis;
    this.ativo = dados.ativoSis === 'S' ? true : false;
    this.status = this.setStatus(dados.statusSsc);
    this.dataUltimaAtualizacao = dados.ultAtualSsc
      ? dados.ultAtualSsc.split('-').reverse().join('/')
      : '';
    // Carrega os modulos do sistema
    if (dados?.sistemaModulo) {
      this.modulos = [];
      dados.sistemaModulo.map(registro => {
        this.modulos.push(registro);
      });
    }
    this.cliente = dados.clienteSsc;
    this.codigoVersao = dados.codversaoSsc;
    this.versao = dados.versaoSve === 'INDEFINIDO' ? '' : dados.versaoSve;
  }

  setStatus(status) {
    switch (status) {
      case 0:
        return 'Indefinido';
      case 1:
        return 'Bloqueado';
      case 2:
        return 'Liberado';
      case 3:
        return 'Somente leitura';
    }
  }

  mapeamento = {
    codigo: 'sistemaSsc',
    descricao: 'nomeSis',
    ativo: 'ativoSis',
    'modulo[].codigo': 'sistemaModulo[].codigoMod',
    'modulo[].descricao': 'sistemaModulo[].nomeMod',
    'modulo[].ativo': 'sistemaModulo[].ativoMod',
  };
}

export { Sistema };
