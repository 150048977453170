import { Empresa } from '../Empresa/Empresa';
import { Usuario } from '../Usuario/Usuario';
import { Padrao } from '../Usuario/Padrao';
import { Storage } from '../Storage/Storage';
import axios from 'axios';
import { api } from '../../api';
class Sessao {
  static id = String();
  static empresa = new Empresa();
  static usuario = new Usuario();
  static padrao = new Padrao();

  static get json() {}

  static set json(dados) {
    if (!dados) {
      return;
    }

    this.id = dados.id;

    this.usuario.codigo = dados.usuario.codigo;
    this.usuario.descricao = dados.usuario.nomeCompleto;
    this.usuario.email = dados.usuario.email;
    this.usuario.telefone = dados.usuario.telefone;
    this.usuario.cliente.codigo = dados.usuario.cliente;
    this.usuario.codigoPessoa = dados.usuario.pessoa;
    this.usuario.tipoPessoa = this.preencheTipoPessoa(dados.usuario.tipoPessoa);
    this.empresa.codigo = dados.empresa.codigo;
    this.empresa.bairro = dados.empresa.bairro;
    this.empresa.cep = dados.empresa.cEP;
    this.empresa.endereco = dados.empresa.endereco;
    this.empresa.descricao = dados.empresa.nome;
    this.empresa.uf = dados.empresa.uF;
    this.padrao.json = dados.usuario.padrao;
  }

  static limpar() {
    this.id = String();
    this.empresa = new Empresa();
    this.usuario = new Usuario();
    this.padrao = new Padrao();
    Storage.removerStorage();
  }

  static isAcessoCliente() {
    return this.usuario.tipoPessoa === 'Cliente' ? true : false;
  }

  static isAcessoContato() {
    return this.usuario.tipoPessoa === 'Contato' ? true : false;
  }

  static isAcessoFuncionario() {
    return this.usuario.tipoPessoa === 'Funcionario' ? true : false;
  }

  static async liberarAcesso(tipo) {
    let response, portas;
    try {
      if (tipo === 'HOME_OFFICE') {
        response = await api.post(
          '/interpreterEx?unit=INICIAR_TRABALHO_REMOTO',
        );

        const { data } = await api.post(
          '/interpreter?unit=FUNCOES_BD_ACESSO_REMOTO&method=TockTock_TekSystem',
        );
        portas = Object.values(data);
      }

      if (tipo === 'TREINAMENTO') {
        response = await api.post(
          '/interpreterEx?unit=INICIAR_TRABALHO_REMOTO&method=AcessoExterno',
        );

        const { data } = await api.post(
          `/interpreter?unit=FUNCOES_BD_ACESSO_REMOTO&method=TockTock_TekSystem`,
        );
        portas = Object.values(data);
      }

      if (tipo === 'CLIENTE') {
        response = await api.post(
          '/interpreterEx?unit=INICIAR_TRABALHO_REMOTO&method=AcessoExterno',
        );

        const { data } = await api.post(
          `/interpreter?unit=FUNCOES_BD_ACESSO_REMOTO&method=TockTock_TekSystem`,
        );
        portas = Object.values(data);
      }
    } catch (error) {
      throw new Error(error.message);
    }

    for (let i = 0; i < 3; i++) {
      try {
        for (const porta of portas) {
          await axios
            .get(`https://ts.teksystem.com.br:${porta}`)
            .catch(() => {});
        }
      } catch (error) {}
    }

    const sessaoAtual = Storage.getSessao();
    sessaoAtual.acessoLiberado = true;
    Storage.setSessao(sessaoAtual);
  }

  static preencheTipoPessoa(tipo) {
    switch (tipo) {
      case 'tpCliente':
        return 'Cliente';
      case 'tpContato':
        return 'Contato';
      case 'tpFuncionario':
        return 'Funcionario';
    }
  }

  static codigoTipoPessoa() {
    switch (this.usuario.tipoPessoa) {
      case 'Funcionario':
        return 1;
      case 'Cliente':
        return 2;
      case 'Contato':
        return 3;
    }
  }
}

export { Sessao };
