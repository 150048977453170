export default [
  {
    path: '/atendimento',
    name: 'novo_atendimento',
    component: () => import('@/views/atendimento/form/FormAtendimento'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/atendimento/consulta',
    name: 'consulta_atendimento',
    component: () => import('@/views/atendimento/consulta/ConsultaAtendimento'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/atendimento/:id',
    name: 'detalhe_atendimento',
    component: () => import('@/views/atendimento/form/FormAtendimento'),
    meta: {
      requireAuth: true,
    },
  },
];
