class ValidarValoresUtil {
	static maiorQueZero(valor) {
		if(Number(valor) === 0) {
			return true
		}

		return false
	}	
}

export { ValidarValoresUtil };

