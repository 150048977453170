import { Cadastro } from '../../bases/Cadastros/Cadastro';

class Setor extends Cadastro {
  constructor(persistente) {
    super();

    this.descricao = persistente ? '' : 'INDEFINIDO';
  }

  get json() {}

  set json(dados) {
    this.codigo = Number(dados.codigoSetor);
    this.descricao = dados.descricaoSetor;
  }

  mapeamento = {
    codigo: 'codigoSetor',
    descricao: 'descricaoSetor',
  };
}

export { Setor };
