import { Enumeration } from '@jslibrary/enumeration';

class TipoFiltro extends Enumeration {
  constructor() {
    super();

    this.add('inteiro', 'Inteiro', 'Inteiro');
    this.add('string', 'String', 'String');
    this.add('cpf', 'CPF', 'CPF');
    this.add('cnpj', 'CNPJ', 'CNPJ');
    this.add('data', 'Data', 'Data');
  }
}

export default new TipoFiltro();
