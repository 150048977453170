import { Cadastro } from '../../bases/Cadastros/Cadastro';

class ModuloSistema extends Cadastro {
  constructor(persistente) {
    super();

    this.descricao = persistente ? '' : 'INDEFINIDO';
    this.codigo = 0;
  }

  get json() {}

  set json(dados) {
    this.codigo = dados.moduloSmod ? dados.moduloSmod : 0;
    this.descricao = dados.descricaoMod ? dados.descricaoMod : 'INDEFINIDO';
  }

  mapeamento = {
    codigo: 'moduloSmod',
    descricao: 'descricaoMod',
  };
}

export { ModuloSistema };
