export default [
  {
    path: '/solicitacao/consulta',
    name: 'consulta_solicitacao',
    component: () => import('@/views/solicitacao/consulta/ConsultaSolicitacao'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/solicitacao/:id',
    name: 'detalhe_solicitacao',
    component: () => import('@/views/solicitacao/form/FormSolicitacao'),
    meta: {
      requireAuth: true,
    },
  },
];
