export default [
  {
    path: '/atividade-projeto/consulta',
    name: 'consulta_atividade_projeto',
    component: () =>
      import('@/views/atividadeProjeto/consulta/ConsultaAtividadeProjeto'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/atividade-projeto',
    name: 'nova_atividade_projeto',
    component: () =>
      import('@/views/atividadeProjeto/form/FormAtividadeProjeto'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/atividade-projeto/:id',
    name: 'detalhe_atividade_projeto',
    component: () =>
      import('@/views/atividadeProjeto/form/FormAtividadeProjeto'),
    meta: {
      requireAuth: true,
    },
  },
];
