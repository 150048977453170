class Padrao {
  constructor() {}

  get json() {}

  set json(dados) {
    this.origemAtendimento = dados.atendimento_Origem;
    this.sistema = dados.atendimento_Sistema;
    this.tipoAtendimento = dados.atendimento_Tipo;
  }
}

export { Padrao };
