export default [
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('@/views/perfil/PerfilUsuario.vue'),
    meta: {
      requireAuth: true,
    },
  },
];
