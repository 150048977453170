export default [
  {
    path: '/principal/:operacao',
    name: 'principal-operacao',
    component: () => import('@/views/principal/Principal'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/principal',
    name: 'principal',
    component: () => import('@/views/principal/Principal'),
    meta: {
      requireAuth: true,
    },
  },
];
