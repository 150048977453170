import { ContatoController } from '../proxy-api/src/services/classes/Contato/ContatoController';
//const controller = new ContatoController();

export default [
  {
    path: '/pessoa',
    name: 'novo_contato',
    component: () => import('@/views/contato/form/FormContato'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/pessoa/consulta',
    name: 'consulta_contato',
    component: () => import('@/views/contato/consulta/ConsultaContato'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/pessoa/:id',
    name: 'detalhe_contato',
    component: () => import('@/views/contato/form/FormContato'),
    meta: {
      requireAuth: true,
    },
  },
];
