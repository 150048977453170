import axios from 'axios';
import { Storage } from '../services/classes/Storage/Storage';

export const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

api.interceptors.request.use(config => {
  validarSeTemCredenciais(config);

  config.headers.Consumer_Key = 'TEKSANSADFV21BG52GDSJE4GV86FD83DKEJFD5F8';

  return config;
});

api.interceptors.response.use(response, error);

function response(r) {
  return r;
}

function error(e) {
  tratamentoAxiosError(e);

  return Promise.reject(e);
}

function tratamentoAxiosError(e) {
  if (!e.response) throw new Error('Sem conexão com servidor');

  const { response } = e;
  const { status, data, config } = response;

  if (status === 401) {
    const isLoginRoute = config.url.includes('auth');
    if (isLoginRoute)
      throw new Error(data.error || 'Erro de autenticação no login.');
    else {
      Storage.removerStorage();
      throw new Error(
        'Sua sessão expirou, redirecionando para página de login.',
      );
    }
  }

  if (!data.error) throw { status, message: data };
  else throw { status, message: data.error };
}

function validarSeTemCredenciais(config) {
  const credenciais = Storage.getCredenciais();

  if (!credenciais) {
    config.headers.Authorization = '';
    return;
  }

  config.headers.Authorization = `Bearer ${credenciais.token_Acesso}`;
}
