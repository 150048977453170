import { Cadastro } from '../../bases/Cadastros/Cadastro';

export class Segmento extends Cadastro {
  constructor() {
    super();
  }

  get jsonParaCliente() {
    return {
      autoincCliseg: this.clienteSegmento,
      segmentoCliseg: this.codigo,
      descricaoSegmento: this.descricao,
      clienteCliseg: this.cliente,
      razaoCli: this.razaoCliente,
    };
  }

  set json(dados) {
    this.clienteSegmento = dados.autoincCliseg;
    this.codigo = Number(dados.autoincSegmento || dados.segmentoCliseg);
    this.descricao = dados.descricaoSegmento;
    this.cliente = dados.clienteCliseg;
    this.razaoCliente = dados.razaoCli;
  }

  mapeamento = {
    codigo: 'autoincSegmento',
    descricao: 'descricaoSegmento',
  };
}
