export default [
  {
    path: '/servico/consulta',
    name: 'consulta_servico',
    component: () => import('@/views/servico/consulta/ConsultaServico'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/servico/:id',
    name: 'detalhe_servico',
    component: () => import('@/views/servico/form/FormServico'),
    meta: {
      requireAuth: true,
    },
  },
];
