export default [
  {
    path: '/projeto/consulta',
    name: 'consulta_projeto',
    component: () => import('@/views/projeto/consulta/ConsultaProjeto'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/projeto/:id',
    name: 'detalhe_projeto',
    component: () => import('@/views/projeto/form/FormProjeto'),
    meta: {
      requireAuth: true,
    },
  },
];
