import { Cadastro } from '../../bases/Cadastros/Cadastro';

export class Cidade extends Cadastro {
  constructor() {
    super();
  }

  mapeamento = {
    codigo: 'codigoCid',
    descricao: 'nomeCid',
    estadoCid: 'estadoCid',
    cep: 'cepCid',
  };

  set json(dados) {
    this.codigo = Number(dados.codigoCid);
    this.descricao = dados.nomeCid;
    this.estado = dados.estadoCid;
    this.cep = dados.cepCid;
  }
}
