import { Contato } from './Contato';
import { Controller } from '../../bases/Controllers/Controller';
import TipoFiltro from '../../../enumerations/TipoFiltro';
import { ValidarValoresUtil } from '../../../utils/ValidarValoresUtil';
import { Sessao } from '../Autenticacao/Sessao';
import { FiltroConsulta } from '../../bases/Consulta/FiltroConsulta';
export class ContatoController extends Controller {
  constructor() {
    super('clientes_contatos', 'Contatos', Contato);
    this.setOrdenacao('nomeClc', 'ASC');
    this.setFiltroInicial('codigoClc', 'MAIOR', 0);
    if (!Sessao.isAcessoFuncionario())
      this.addFiltroFixo('clienteClc', 'IGUAL', Sessao.usuario.cliente.codigo);
    this.addFiltroAplicavel('codigoClc', 'Código', TipoFiltro.Inteiro);
    this.addFiltroAplicavel('nomeClc', 'Nome', TipoFiltro.String, true);
    this.addFiltroAplicavel('emailClc', 'E-mail', TipoFiltro.String);
    this.addFiltroAplicavel('cpfClf', 'CPF', TipoFiltro.CPF);
    if (Sessao.isAcessoFuncionario()) {
      this.addFiltroAplicavel(
        'clienteClc',
        'Código do cliente',
        TipoFiltro.Inteiro,
      );
    }
  }

  getDescricao(registro) {
    return registro.descricao;
  }

  getDetalhe(registro) {
    if (!Sessao.isAcessoFuncionario())
      return `<div class="text-caption">${registro.email} <br> <b class="${
        registro.ativo ? 'success--text' : 'error--text'
      }"> ${registro.ativo ? 'ATIVO' : 'INATIVO'} </b>
   </div>`;

    return `<div class="text-caption">${registro.email} <br> ${
      registro.cliente.descricao
    } | <b class="${registro.ativo ? 'success--text' : 'error--text'}"> ${
      registro.ativo ? 'ATIVO' : 'INATIVO'
    } </b>
   </div>`;
  }

  getCodigo(contato) {
    return contato.codigo;
  }

  async validar(registro) {
    if (ValidarValoresUtil.maiorQueZero(registro.cliente.codigo))
      throw new Error('<b>Cliente</b> não preenchido');

    if (!registro.descricao) throw new Error('<b>Nome</b> não preenchido');

    if (!registro.email) throw new Error('<b>E-mail</b> não preenchido');

    if (
      String(registro.telefone).length > 0 &&
      String(registro.telefone).length < 11
    )
      throw new Error('<b>Telefone</b> não preenchido corretamente');

    if (ValidarValoresUtil.maiorQueZero(registro.setor.codigo))
      throw new Error('<b>Setor</b> não preenchido');
  }

  async buscaPorCPF(cpf) {
    const filtros = [new FiltroConsulta('cpfClf', cpf)];
    await this.buscarRegistros(filtros, true);
    return this.registros[0];
  }

  async buscarPorEmail(email) {
    const filtros = [new FiltroConsulta('emailClc', email)];
    await this.buscarRegistros(filtros, true);
    return this.registros[0];
  }
}
