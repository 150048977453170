class Storage {
  static CREDENCIAIS = '@teksystem-credencias';
  static SESSAO = '@teksystem-sessao';

  static defineTipoStorage(permanecerConectado) {
    if (permanecerConectado) {
      localStorage.setItem('@teksystem-permanecerConectado', true);
    } else {
      localStorage.setItem('@teksystem-permanecerConectado', false);
    }
  }

  static setUltimoUsuario(usuario) {
    localStorage.setItem('@teksystem-ultimoUsuario', usuario);
  }

  static setUltimotTipoPessoa(tipoAcesso) {
    localStorage.setItem('@teksystem-ultimoTipoPessoa', tipoAcesso);
  }

  static getUltimoUsuario() {
    return localStorage.getItem('@teksystem-ultimoUsuario');
  }

  static getUltimotTipoPessoa() {
    return localStorage.getItem('@teksystem-ultimoTipoPessoa');
  }

  static getTipoStorage() {
    const tipoStorage = localStorage.getItem('@teksystem-permanecerConectado');
    if (tipoStorage === 'true') return localStorage;
    return sessionStorage;
  }

  static setCredenciais(credenciais) {
    const storage = this.getTipoStorage();
    storage.setItem(this.CREDENCIAIS, JSON.stringify(credenciais));
  }

  static getCredenciais() {
    const storage = this.getTipoStorage();
    const credenciais = storage.getItem(this.CREDENCIAIS);

    if (!credenciais) return false;
    return JSON.parse(credenciais);
  }

  static setSessao(sessao) {
    const storage = this.getTipoStorage();
    storage.setItem(this.SESSAO, JSON.stringify(sessao));
  }

  static getSessao() {
    const storage = this.getTipoStorage();
    const sessao = storage.getItem(this.SESSAO);

    if (!sessao) return false;
    return JSON.parse(sessao);
  }

  static removerStorage() {
    const storage = this.getTipoStorage();
    storage.removeItem(this.CREDENCIAIS);
    storage.removeItem(this.SESSAO);
    localStorage.removeItem('@teksystem-permanecerConectado');
  }
}

export { Storage };
