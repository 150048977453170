export default [
  {
    path: '/proposta/consulta',
    name: 'consulta_proposta',
    component: () => import('@/views/proposta/consulta/ConsultaProposta'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/proposta/:id',
    name: 'detalhe_proposta',
    component: () => import('@/views/proposta/form/FormProposta'),
    meta: {
      requireAuth: true,
    },
  },
];
