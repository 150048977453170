export default [
  {
    path: '/atendimento-presencial',
    name: 'novo_atendimento_presencial',
    component: () =>
      import('@/views/atendimentoPresencial/form/FormAtendimentoPresencial'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/atendimento-presencial/consulta',
    name: 'consulta_atendimento_presencial',
    component: () =>
      import(
        '@/views/atendimentoPresencial/consulta/ConsultaAtendimentoPresencial'
      ),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/atendimento-presencial/:id',
    name: 'detalhe-atendimento-presencial',
    component: () =>
      import('@/views/atendimentoPresencial/form/FormAtendimentoPresencial'),
    meta: {
      requireAuth: true,
    },
  },
];
